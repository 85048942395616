<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span v-if="label" class="registry_fields_label">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
    <el-input v-if="isTextArea" v-bind:value="value"
              type="textarea"
              :title="!label ? tooltip: ''"
              :autosize="{minRows: minRows || 2, maxRows: minRows || 2}"
              :size="size"
              :placeholder="placeholder || $locale.main.placeholder.string_field"
              v-on:input="$emit('input', $event)" :name="name" :readonly="readonly"></el-input>
    <el-input v-else
    v-bind:value="value"
              type="text"
              :title="!label ? tooltip: ''"
              :autosize="{minRows: minRows || 2, maxRows: minRows || 2}"
              :size="size"
              v-mask="mask"
              :placeholder="placeholder || $locale.main.placeholder.string_field"
              v-on:input="$emit('input', $event)" :name="name" :readonly="readonly"></el-input>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
export default {
  name: 'string_field',
  mixins: [mixin, registryMixin],
  props: {
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean
    },
    isTextArea: {
      type: Boolean,
      description: 'Текстовое поле',
      default: false
    },
    minRows: {
      description: 'Строк в высоту',
      type: String,
      default: '2'
    },
    mask: {
      description: 'Маска',
      type: String,
      default: null,
      options: {
        tooltip: {
          show: true,
          content: `# - Числа (0-9)<br>
                    A - Латиница (a-z,A-Z)<br>
                    N - Числа и латиница (a-z,A-Z,0-9)<br>
                    X - Любой символ<br>
                    Я - Кириллица (a-я,A-Я)`
        }
      }
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
